<template>
  <Header title="Create new trip" subtitle="Create a new trip and have fun"></Header>
  <div class="setup_trip">
    <div class="context">
      <form
        class="new_trip"
        id="new_trip"
        v-on:submit.prevent="onSubmit"
        @submit.prevent="submitForm"
        method="post"
      >
        <input type="hidden" name="trip[location_id]" id="trip_location_id" />
        <input type="hidden" name="trip[type_name]" id="trip_type_name" />
        <ul class="errors" data-form-target="errors" style="display: none"></ul>

        <div id="step1">
          <p>
            Alright, let's get ready to set up a new trip. We need a little bit of
            information from you and we can get started.
          </p>
          <div class="field">
            <label class="label" for="trip_location">Where are you going to?</label>
            <div class="control">
              <input
                v-on:keyup="findLocations($event.target.value)"
                class="input"
                placeholder="Amsterdam, the Netherlands"
                autocomplete="off"
                type="text"
                name="trip[location]"
                id="trip_location"
              />
            </div>
          </div>
          <div id="location_results">
            <div
              @click.prevent="selectLocation(result.id)"
              class="result"
              v-for="result in results"
              :key="result.id"
            >
              <div
                class="fflag ff-wave fflag ff-sm"
                v-bind:class="'fflag-' + result.country"
              ></div>
              <div>
                <div class="name">{{ result.location_name }}</div>
                <div class="area">{{ result.location_area }}</div>
              </div>
            </div>
          </div>
        </div>
        <div id="step2" style="display: none">
          <div class="field">
            <label class="label" for="trip_type_name">What kind of trip is it?</label>
            <div class="control"></div>
            <div id="types">
              <div
                @click.prevent="selectType(result)"
                class="type"
                v-bind:class="result"
                v-for="result in trip_types"
                :key="result"
              >
                <div class="name">{{ result }}</div>
              </div>
            </div>
          </div>
        </div>
        <div id="step3" style="display: none">
          <div class="field">
            <label class="label" for="trip_name"
              >And lastly, what would you name your trip?</label
            >
            <div class="control">
              <input
                class="input"
                v-model="trip_name"
                placeholder="Family getaway"
                type="text"
                name="trip[name]"
                id="trip_name"
              />
            </div>
          </div>
          <div class="field">
            <div class="control submit center">
              <button type="submit" class="button medium noimage reverse">Create trip</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: "",
      results: [],
      trip_types: [
        "city",
        "culture",
        "nature",
        "leisure",
        "mountains",
        "snow",
        "roadtrip",
        "beach",
      ],
      location_id: null,
      type_name: null,
      trip_name: null,
    };
  },
  computed: {},
  watch: {
    "$store.state.trip.new_trip": function () {
      this.$router.push(`/trips/${this.$store.state.trip.new_trip.id}`);
    },
  },
  methods: {
    findLocations(value) {
      var self = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        self.locationLookup(self, value);
      }, 200);
    },
    locationLookup(self, val) {
      fetch("https://destinations.trivvel.com/location.json?q=" + val)
        .then((response) => response.json())
        .then((results) => {
          self.results = [];
          results.forEach(function (val, index) {
            var data = val.name.split(",");
            var location_name = data[0];
            data.shift();
            var location_area = data.join(", ");

            self.results.push({
              id: val.id,
              name: val.name,
              country: val.country_code,
              location_name: location_name,
              location_area: location_area,
            });
          });
        });
    },
    selectLocation(location_id) {
      this.location_id = location_id;
      document.getElementById("step1").style.display = "none";
      document.getElementById("step2").style.display = "block";
    },
    selectType(type_name) {
      this.type_name = type_name;
      document.getElementById("step2").style.display = "none";
      document.getElementById("step3").style.display = "block";
    },
    submitForm() {
      var trip = {
        name: this.trip_name,
        type_name: this.type_name,
        location_id: this.location_id,
      };

      var self = this;

      this.$store.dispatch("trip/create", trip);
    },
  },
};
</script>
<style lang="sass" scoped>
.setup_trip
  width: 60%
  margin: 0 auto

  @media (max-width: $media-mobile)
    width: 90%

p
  padding: 2rem

label
  margin-bottom: 2rem

input[type="text"]
  font-weight: 400
  padding: 2.5rem 1.5rem
  border-radius: 8rem
  border: 1px solid #ccc
  font-size: 16px

.field
  margin-top: 4rem

#location_results
  .fflag
    margin-right: 25px
    margin-left: 15px
    display: flex
    align-self: center
    border-radius: 60px
    width: 25px
    height: 25px

  .result
    cursor: pointer
    padding: 1rem
    display: flex
    margin: 2rem 0
    border-radius: 16px
    text-align: left
    background-color: white
    box-shadow: -1px 3px 4px 1px rgba(0,0,0,0.1)

    &:hover
      box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2)

    .name
      font-weight: bold
      font-size: 2.2rem
      font-family: 'Jost'
      line-height: 3rem

    .area
      font-size: 0.9rem
      font-size: 1.5rem
      color: #999

#types
  .type
    height: 10rem
    padding: 1rem
    margin: 3rem 0rem
    border-radius: 12px
    background-size: cover
    background-position: center
    cursor: pointer
    width: 100%
    text-transform: capitalize

    .name
      color: white
      line-height: 8rem
      font-size: 2.6rem
      font-weight: 600
      text-shadow: 0px 2px 2px rgba(0,0,0,0.8)

    &.nature
      background-image: url('~@/assets/trip_types/nature.jpg')

    &.leisure
      background-image: url('~@/assets/trip_types/leisure.jpg')

    &.beach
      background-image: url('~@/assets/trip_types/beach.jpg')

    &.roadtrip
      background-image: url('~@/assets/trip_types/roadtrip.jpg')

    &.snow
      background-image: url('~@/assets/trip_types/snow.jpg')

    &.city
      background-image: url('~@/assets/trip_types/city.jpg')

    &.mountains
      background-image: url('~@/assets/trip_types/mountains.jpg')

    &.culture
      background-image: url('~@/assets/trip_types/culture.jpg')

input[type='submit']
  margin-top: 2rem
  border: 0
  border-radius: 12px
  background-color: $primary-color
  font-size: 2rem
</style>
